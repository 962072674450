








































































import { defineComponent, ref, toRef, watch } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'
import axios from 'axios'

import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'
import VInput from '@/components/form/VInput.vue'
import VueRecaptcha from 'vue-recaptcha'

import { getApiUrl, passwordRegex } from '@/inc/app.config'
import { getCallbackUrl } from '@/inc/utils'

import CustomLink from '@/components/g/CustomLink.vue'

export default defineComponent({
  name: 'my-reset',
  components: {
    FeedbackMessage,
    VInput,
    VueRecaptcha,
    CustomLink,
  },
  setup(_props, ctx) {
    const { chrome } = useState(['chrome'])
    const recaptcha = ref('')
    const recaptchaKey = ref(chrome.value.data?.recaptcha)
    const hasError = ref(false)
    const message = ref('')
    const isLoading = ref(false)
    const { $logger } = ctx.root
    // Phase #1: reset, ask for a token
    const { email: userEmail } = useState('user', ['email'])
    const email = ref(userEmail.value || '')
    const { i18n } = useState('my', ['i18n'])
    const callback = getCallbackUrl('/reset/password/')
    const onReset = async () => {
      isLoading.value = true
      hasError.value = false
      message.value = ''

      try {
        const res = await axios.post(
          `${getApiUrl()}/users/reset/sendmail?Callback=${callback}&Email=${
            email.value
          }`,
          {
            'g-recaptcha-response': recaptcha.value,
          }
        )

        if (res.status === 200) {
          message.value = i18n.value.resetPassword.confirm
        } else {
          hasError.value = true
          res.data.message.value = message
        }
      } catch (error) {
        $logger.error('ResetPasswordError 😱', error)
        hasError.value = true
        if (
          error.response.data.originalBody.ErrorCode === 'UNKNOWN_USER_MAIL'
        ) {
          message.value = i18n.value.resetPassword.errorUnknownUserMail
        } else {
          message.value = error.response.data.message
        }
      }

      isLoading.value = false
    }

    watch(toRef(chrome.value, 'data'), newValue => {
      recaptchaKey.value = newValue.recaptcha
    })

    const setRecaptcha = (res: string) => {
      recaptcha.value = res
    }

    const resetRecaptcha = () => {
      recaptcha.value = ''
    }

    return {
      chrome,
      confirm,
      email,
      hasError,
      i18n,
      isLoading,
      message,
      onReset,
      passwordRegex,
      recaptcha,
      recaptchaKey,
      resetRecaptcha,
      setRecaptcha,
    }
  },
})
