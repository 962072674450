var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-reset wrapper m-l"},[(_vm.i18n.resetPassword)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xxxs-20 col-s-14"},[(_vm.i18n.resetPassword.title)?_c('h1',{staticClass:"fatline mb-m"},[_vm._v(" "+_vm._s(_vm.i18n.resetPassword.title)+" ")]):_vm._e(),(_vm.i18n.resetPassword.text)?_c('p',{staticClass:"mb-s",domProps:{"innerHTML":_vm._s(_vm.i18n.resetPassword.text)}}):_vm._e(),_c('form',[_c('div',{staticClass:"row mb-s"},[_c('v-input',{staticClass:"col-xxxs col-l-10",attrs:{"id":"Email","label":_vm.i18n.resetPassword.email,"required":true,"type":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),(_vm.message)?_c('feedback-message',{staticClass:"col-xxxs-20 mt-s",attrs:{"content":{
              type: _vm.hasError ? 'error' : 'success',
              htmltext: _vm.message,
            }}}):_vm._e()],1),(_vm.recaptchaKey)?_c('vue-recaptcha',{staticClass:"mb-s",attrs:{"language":_vm.chrome.currentLang !== null ? _vm.chrome.currentLang : 'en',"loadRecaptchaScript":true,"sitekey":_vm.recaptchaKey},on:{"expired":_vm.resetRecaptcha,"verify":_vm.setRecaptcha}}):_vm._e(),_c('CustomLink',{staticClass:"submit",class:{ 'is-loading': _vm.isLoading },attrs:{"content":{
            label: _vm.i18n.resetPassword.button.label,
            icon: {
              size: '0 0 24 24',
              name: 'arrows-large-right',
            },
          },"disabled":!_vm.recaptcha,"modifiers":['btn', 'icon', 'arrow'],"type":'button'},on:{"btnClick":_vm.onReset}})],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }