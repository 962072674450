

































































import { defineComponent, computed } from '@vue/composition-api'
import { FeedbackMessage } from '@/inc/types/components'

export default defineComponent({
  name: 'feedback-message',
  components: {},
  props: {
    addStyles: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: Object as () => FeedbackMessage,
      required: true,
    },
    isSvg: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  setup(props) {
    const icon = computed(() => {
      const icons = {
        success: 'check-filled',
        warning: 'error-filled',
        error: 'error-filled',
      }

      return `ui-${icons[props.content.type]}`
    })

    return { icon }
  },
})
